import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from "chart.js";
import Layout from "../Componets/Layout";
import { Link } from "react-router-dom";
import Sidebar from "../Pages/Sidebar";
import * as XLSX from 'xlsx';
import AdHeader from "../Componets/AdHeader";

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

const LICSipCalculator = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [monthlyInvestment, setMonthlyInvestment] = useState("");
  const [rateOfInterest, setRateOfInterest] = useState("");
  const [tenure, setTenure] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [amounts, setAmounts] = useState([]);
  const [error, setError] = useState("");

  const calculateSIP = () => {
    // Reset error state
    setError("");

    // Validation
    if (!monthlyInvestment || monthlyInvestment <= 0) {
      setError("Monthly Investment must be a positive number");
      return;
    }

    if (!rateOfInterest || rateOfInterest <= 0) {
      setError("Rate of Interest must be a positive number");
      return;
    }

    if (!tenure || tenure <= 0) {
      setError("Tenure must be a positive number");
      return;
    }

    const r = rateOfInterest / 100 / 12;
    const n = tenure * 12;
    const investment = monthlyInvestment;

    const totalAmountValue =
      investment * (((1 + r) ** n - 1) * (1 + r)) / r;

    const interest = totalAmountValue - investment * n;

    setTotalAmount(totalAmountValue.toFixed(2));
    setTotalInterest(interest.toFixed(2));

    // Populate amounts for each month (for graph)
    const dataPoints = [];
    for (let i = 1; i <= n; i++) {
      const amount = investment * (((1 + r) ** i - 1) * (1 + r)) / r;
      dataPoints.push(amount.toFixed(2));
    }
    setAmounts(dataPoints);
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  
const exportToExcel = () => {
const wb = XLSX.utils.book_new();
const ws = XLSX.utils.json_to_sheet([
  { 'Total Amount	': totalAmount },
  { 'Total Interest': totalInterest }
]);

XLSX.utils.book_append_sheet(wb, ws, 'Post Office RD Calculator Data');
XLSX.writeFile(wb, 'Post_Office_RD_Calculator.xlsx');
};

  return (
    <Layout    pageTitle="LIC SIP Calculator | LICCalculators"
    metaDescription="Use the LIC SIP Calculator to estimate your Systematic Investment Plan (SIP) returns and benefits."
    metaKeywords="LIC SIP calculator, SIP returns calculator, LIC investment"
    canonicalUrl="https://liccalculators.com/lic-sip-calculator"
    robotsContent="index, follow"
    ogTitle="LIC SIP Calculator | LICCalculators"
    ogDescription="Estimate your Systematic Investment Plan (SIP) returns with our LIC SIP Calculator."
    ogUrl="https://liccalculators.com/lic-sip-calculator"
    ogImage="https://liccalculators.com/assets/images/lic-sip-calculator-og-image.jpg"
    schemaData={{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "url": "https://liccalculators.com/lic-sip-calculator",
      "name": "LIC SIP Calculator",
      "description": "Calculate your SIP returns and benefits using the LIC SIP Calculator.",
      "publisher": {
        "@type": "Organization",
        "name": "LICCalculators",
        "logo": {
          "@type": "ImageObject",
          "url": "https://liccalculators.com/assets/images/logo.png"
        }
      }
    }}>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-1">

        </div>
        <div className="col-md-8 main-section">
            <div className="container">
            <div className='header-ads'>
              <AdHeader/>
            </div>
            <div className='bred'>
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/all-tools" style={{textDecoration:'none' , color:'black'}}>All Tools</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Lic Sip Calculator</li>
                </ol>
                    </nav>
                </div>
                <h1 className='text-center'>Lic Sip Calculator</h1>
                <p className='text-center'>Calculate returns on your Investment Plan.</p>
                <div className="container text-center">
                  <div className="row">
                   <div className="col-md-12">
                   <label htmlFor="Monthly" className="form-label label"><b>Monthly Investment (INR): </b><span className="text-danger">*</span></label>
                   <input
                  type="number"
                  className="form-control"
                  id="investmentAmount"
                  value={monthlyInvestment}
                  onChange={(e) => setMonthlyInvestment(e.target.value)}
                  
                  placeholder='Enter Monthly Investment'
                  required
                />
                   </div>
                  </div>
                  <div className="row input-row">
                    <div className="col-md-12">
                    <label htmlFor="rate" className="form-label label"><b>Rate of Interest (%):</b> <span className="text-danger">*</span> </label>
                    <input
                      type="number"
                      className="form-control"
                      id="investmentAmount"
                      value={rateOfInterest}
                      onChange={(e) => setRateOfInterest(e.target.value)}
                      placeholder="Enter Rate of Interest"
                      required
                    />
                    </div>
                  </div>
                  <div className="row input-row">
                    <div className="col-md-12">
                    <label htmlFor="Tenure"  className="form-label label"><b>Tenure (Years): </b> <span className="text-danger">*</span></label>
                    <input
                    type="number"
                    className="form-control"
                    id="investmentPeriod"
                    value={tenure}
                    onChange={(e) => setTenure(e.target.value)}
                    placeholder="Enter Sip Tenure"
                    required
                  />
                    </div>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                  </div>
                </div>
                <div className="row input-row">
                  <dv className="col-md-12">
                  <button id="annualInterestRate"  className="btn btn-success" onClick={calculateSIP}>Calculate</button>
                  </dv>
                </div>
                {totalAmount > 0 && (
        <div className="container">
        <hr></hr>
        <h5>Summary :</h5> <br></br>
        <div className="row">
          <div className="table-responsive">
            <table className="table">
            <tbody>
              <tr>
                <th scope="col">Total Amount</th>
                <td>{formatCurrency(totalAmount)}</td>
              </tr>
              <tr>
                <th scope="col">Total Interest</th>
                <td>{formatCurrency(totalInterest)}</td>
              </tr>
            </tbody>

            </table>
          </div>
          <button className="btn btn-success" id='annualInterestRate' onClick={exportToExcel} >Export to Excel</button>
        </div>
          <div className="container">
            <div className="row">
            <h5 className='text-center' style={{marginTop:'20px'}}>Data In Graph Formate :</h5>
            <Line
              data={{
                labels: Array.from({ length: tenure * 12 }, (_, i) => i + 1),
                datasets: [
                  {
                    label: "Amount Over Time (INR)",
                    data: amounts,
                    fill: false,
                    borderColor: "rgb(75, 192, 192)",
                    tension: 0.1,
                  },
                ],
              }}
              options={{
                scales: {
                  x: {
                    title: { display: true, text: "Months" },
                  },
                  y: {
                    title: { display: true, text: "Amount (INR)" },
                  },
                },
              }}
            />
            </div>
          </div>
        </div>
      )}
      <div className="container">
  <div className="row" style={{marginTop:"50px"}}>
    <hr />
    <h3 className='text-center text-black'>About LIC SIP Calculator</h3>
    <hr />
    <p style={{fontFamily:'sans-serif'}}>
      The LIC SIP Calculator is an invaluable financial tool designed for individuals looking to estimate the potential future value of their investments in a Systematic Investment Plan (SIP). This tool is tailored for LIC's investment-linked plans, which offer a structured and disciplined approach to saving and investing. By regularly contributing a fixed amount into mutual funds, Unit Linked Insurance Plans (ULIPs), or other LIC investment schemes, investors can benefit from the power of compounding and systematic investment strategies.
    </p>
    
    <h5>Key Features of the LIC SIP Calculator:</h5>
    <div style={{fontFamily:"sans-serif"}}>
      <ul>
        <li><b>Monthly Investment Input:</b> Enter the fixed amount you intend to invest every month. This allows you to project how your regular contributions will accumulate over time.</li>
        <li><b>Expected Rate of Return:</b> Input the anticipated annual rate of return. This rate, derived from historical data or expected market performance, is crucial for estimating the growth of your investment.</li>
        <li><b>Investment Tenure:</b> Specify the duration of your investment in years. The calculator converts this into the total number of investment months, providing a detailed projection of growth.</li>
        <li><b>Compound Interest Calculation:</b> Utilizes the compound interest formula to compute the future value of your SIP, factoring in the effects of interest on both the principal and accumulated interest.</li>
        <li><b>Additional Contributions:</b> Optionally include one-time lump sum investments or sporadic additional contributions to see their impact on the overall investment growth.</li>
        <li><b>Flexible Scenarios:</b> Adjust inputs such as investment amounts, tenure, and expected returns to simulate different scenarios and see how they influence your future value.</li>
      </ul>
    </div>
    
    <h5>Benefits of Using an LIC SIP Calculator:</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Easy Forecasting:</b> Quickly and efficiently see how your monthly investments will grow over time, helping you set realistic financial goals.</li>
        <li><b>Informed Decision Making:</b> Provides insights into how different investment amounts and rates of return will affect your total returns, enabling you to make better investment choices.</li>
        <li><b>Visual Growth Representation:</b> Offers graphical representations such as line graphs and bar charts, making it easier to understand and interpret the growth of your investments.</li>
        <li><b>Scenario Analysis:</b> Allows you to experiment with different investment strategies and compare results, aiding in the selection of the best approach for achieving your financial objectives.</li>
        <li><b>Financial Planning:</b> Assists in long-term financial planning by showing the cumulative effect of regular investments, helping you achieve your financial goals more effectively.</li>
        <li><b>Comprehensive Reports:</b> Some calculators offer detailed reports and summaries that can be exported to Excel or PDF for detailed analysis and record-keeping.</li>
      </ul>
    </div>
    
    <h5>Use Case Example:</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <p>For instance, if you invest ₹5,000 monthly in an LIC SIP for a period of 10 years with an expected annual return rate of 10%, the calculator will help you visualize the growth of your investment over 120 months (10 years). It will show you the total amount contributed, the interest earned, and the final value of your investment, allowing you to plan effectively for future financial needs such as retirement, education, or major purchases.</p>
    </div>
    
    <p style={{fontFamily:'sans-serif'}}>
      The LIC SIP Calculator is a powerful tool for anyone looking to build wealth through disciplined and systematic investing. By offering accurate projections and valuable insights, it empowers investors to make informed decisions and achieve their long-term financial goals with confidence.
    </p>
  </div>
</div>

            </div>
        </div>
        <div className="col-md-3">
        <Sidebar/>
        </div>
      </div>
    </div>
   
    </Layout>
  )
};

export default LICSipCalculator;
