// src/components/AdComponent.js
import React, { useEffect } from 'react';

const AdSidebar = () => {
  useEffect(() => {
    // Load Google AdSense script dynamically
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5941451479338770';
    script.async = true;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);

    // Initialize Google AdSense
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div>
      {/* Google AdSense ad */}
      <ins className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-5941451479338770"
        data-ad-slot="9753397862"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div>
  );
};

export default AdSidebar;
