import React, { useState , useEffect} from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import Layout from '../Componets/Layout';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Sidebar from '../Pages/Sidebar';
import AdHeader from '../Componets/AdHeader';

const JeevanAnandCalculator = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [sumAssured, setSumAssured] = useState('');
  const [policyTerm, setPolicyTerm] = useState('');
  const [age, setAge] = useState('');
  const [bonusRate, setBonusRate] = useState('40'); // Default bonus rate
  const [calculationData, setCalculationData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const SA = parseFloat(sumAssured);
    const PT = parseInt(policyTerm);
    const bonus = parseFloat(bonusRate);

    const monthlyPremium = (SA * 1.1) / PT / 12; // Simplified premium calculation
    const totalPremiumPaid = monthlyPremium * 12 * PT;

    // Calculate the annual and cumulative bonuses
    let annualBonuses = [];
    let cumulativeBonuses = [];
    let sumAssuredPlusBonus = [];
    let cumulativeBonus = 0;

    for (let year = 1; year <= PT; year++) {
      let annualBonus = (SA * bonus) / 1000;
      cumulativeBonus += annualBonus;
      annualBonuses.push(annualBonus.toFixed(2));
      cumulativeBonuses.push(cumulativeBonus.toFixed(2));
      sumAssuredPlusBonus.push((SA + cumulativeBonus).toFixed(2));
    }

    setCalculationData({
      monthlyPremium: monthlyPremium.toFixed(2),
      totalPremiumPaid: totalPremiumPaid.toFixed(2),
      maturityAmount: (SA + cumulativeBonus).toFixed(2),
      annualBonuses: annualBonuses,
      cumulativeBonuses: cumulativeBonuses,
      sumAssuredPlusBonus: sumAssuredPlusBonus,
      years: Array.from({ length: PT }, (_, i) => i + 1),
    });
  };

  const bonusChartData = calculationData
    ? {
        labels: calculationData.years,
        datasets: [
          {
            label: 'Annual Bonus (INR)',
            data: calculationData.annualBonuses,
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
          {
            label: 'Cumulative Bonus (INR)',
            data: calculationData.cumulativeBonuses,
            backgroundColor: 'rgba(255, 206, 86, 0.6)',
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 1,
          },
          {
            label: 'Sum Assured + Bonus (INR)',
            data: calculationData.sumAssuredPlusBonus,
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
      }
    : null;

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      { 'Monthly Premium:': calculationData.monthlyPremium },
      { 'Total Premium Paid:	': calculationData.totalPremiumPaid },
      { 'Maturity Amount:': calculationData.maturityAmount },
    ]);

    XLSX.utils.book_append_sheet(wb, ws, 'JeevanAnandData');
    XLSX.writeFile(wb, 'Lic_Jeevan_Anand_Calculator.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
    <Layout pageTitle="LIC Jeevan Anand Calculator | LICCalculators"
    metaDescription="Calculate premiums and benefits of LIC Jeevan Anand policy using our calculator."
    metaKeywords="LIC Jeevan Anand calculator, Jeevan Anand premiums, LIC policy calculator"
    canonicalUrl="https://liccalculators.com/lic-jeevan-anand-calculator"
    robotsContent="index, follow"
    ogTitle="LIC Jeevan Anand Calculator | LICCalculators"
    ogDescription="Calculate premiums and benefits of LIC Jeevan Anand policy with our comprehensive calculator."
    ogUrl="https://liccalculators.com/lic-jeevan-anand-calculator"
    ogImage="https://liccalculators.com/img/new_logo.png"
    schemaData={{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "url": "https://liccalculators.com/lic-jeevan-anand-calculator",
      "name": "LIC Jeevan Anand Calculator",
      "description": "Calculate premiums and benefits of LIC Jeevan Anand policy using our calculator.",
      "publisher": {
        "@type": "Organization",
        "name": "LICCalculators",
        "logo": {
          "@type": "ImageObject",
          "url": "https://liccalculators.com/img/new_logo.png"
        }
      }
    }}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-8 main-section'>
            <div className='container'>
            <div className='header-ads'>
              <AdHeader/>
            </div>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <Link to='/' style={{ textDecoration: 'none', color: 'black' }}>
                      Home
                    </Link>
                  </li>
                  <li className='breadcrumb-item'>
                    <Link to='/all-tools' style={{ textDecoration: 'none', color: 'black' }}>
                      All Tools
                    </Link>
                  </li>
                  <li className='breadcrumb-item active' aria-current='page'>
                    Lic Jeevan Anand Calculator
                  </li>
                </ol>
              </nav>

              <form onSubmit={handleSubmit}>
                <h1 className='text-center'>Lic Jeevan Anand Calculator</h1>
                <p className='text-center'>Calculate returns on your Investment Plan.</p>
                <div className='container text-center'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label htmlFor='Insured' className='form-label label'>
                        <b>Insured Amount (INR): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type='number'
                        value={sumAssured}
                        className='form-control'
                        id='investmentAmount'
                        name='Insured Amount'
                        onChange={(e) => setSumAssured(e.target.value)}
                        placeholder='Enter Insured Amount'
                        min='0'
                        required
                      />
                    </div>
                  </div>
                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='Policy Term' className='form-label label'>
                        <b>Policy Term (Years): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type='number'
                        value={policyTerm}
                        className='form-control'
                        id='investmentPeriod'
                        onChange={(e) => setPolicyTerm(e.target.value)}
                        placeholder='Enter Policy Term'
                        required
                      />
                    </div>
                  </div>
                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='Age' className='form-label label'>
                        <b>Age (Years): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type='number'
                        value={age}
                        className='form-control'
                        id='investmentPeriod'
                        onChange={(e) => setAge(e.target.value)}
                        placeholder='Enter Your Age'
                        required
                      />
                    </div>
                  </div>
                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='Bonus'>
                        <b>Bonus Rate (per 1000 INR): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type='number'
                        value={bonusRate}
                        className='form-control'
                        id='annualInterestRate'
                        onChange={(e) => setBonusRate(e.target.value)}
                        placeholder='Enter Bonus Rate per 1000 Inr'
                        min='0'
                        required
                      />
                    </div>
                  </div>
                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <button type='submit' className='btn btn-success'>
                        Calculate
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              {calculationData && (
                <div className='container'>
                  <hr />
                  <h5>Summary :</h5>
                  <br />
                  <div className='row'>
                    <div className='table-responsive'>
                      <table className='table'>
                        <tbody>
                          <tr>
                            <th scope='col'>Monthly Premium:</th>
                            <td>{formatCurrency(calculationData.monthlyPremium)}</td>
                          </tr>
                          <tr>
                            <th scope='col'>Total Premium Paid:</th>
                            <td>{formatCurrency(calculationData.totalPremiumPaid)}</td>
                          </tr>
                          <tr>
                            <th scope='col'>Maturity Amount:</th>
                            <td>{formatCurrency(calculationData.maturityAmount)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <button className='btn btn-success' id='annualInterestRate'  onClick={exportToExcel}>
                      Export to Excel
                    </button>
                  </div>

                  {/* Render the chart only if bonusChartData is available */}
                  {bonusChartData && (
                    <div style={{marginTop:'20px'}}>
                      <h2>Bonus and Maturity Amount Breakdown</h2>
                      <Bar data={bonusChartData} />
                    </div>
                  )}
                </div>
              )}
              <div className='container'>
  <div className='row' style={{marginTop:'50px0'}}>
    <hr />
    <h3 className='text-center text-black'>About LIC Jeevan Anand Calculator</h3>
    <hr />
    <p style={{fontFamily:'sans-serif'}}>
      The LIC Jeevan Anand Calculator is a valuable tool that helps you estimate the potential returns and benefits of LIC's popular endowment policy, the Jeevan Anand Plan. 
      This plan is unique in that it offers both life insurance coverage and a savings component. It provides a lump-sum payout at the end of the policy term (maturity), 
      while the life insurance coverage continues for the policyholder's lifetime, even after the policy matures. 
      The calculator gives you clarity on how much you will receive at the end of the policy term and the premium you need to pay throughout the tenure.
    </p>

    <h4>How the Calculator Works:</h4>
    <div style={{fontFamily:'sans-serif'}}>
      <p>
        The LIC Jeevan Anand Calculator is simple and efficient. Based on the input values you provide, such as sum assured, policy term, and your age, it computes the following:
      </p>
      <ul>
        <li>
          <b>Monthly Premium:</b> The monthly installment you need to pay to keep the policy active. It is calculated based on factors like the sum assured and policy term.
        </li>
        <li>
          <b>Total Premium Paid:</b> The total amount you will have paid over the entire policy term, helping you understand your financial commitment.
        </li>
        <li>
          <b>Maturity Amount:</b> The total payout you will receive at the end of the policy term. This includes the sum assured as well as the bonuses accrued during the policy term.
        </li>
      </ul>
    </div>

    <h4>Key Benefits of LIC Jeevan Anand Plan:</h4>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li>
          <b>Lifetime Coverage:</b> Even after the policy matures and the maturity amount is paid, the life insurance coverage continues, providing lifelong protection.
        </li>
        <li>
          <b>Bonus Facility:</b> The plan is eligible for bonuses, declared annually by LIC, which significantly enhances the maturity payout.
        </li>
        <li>
          <b>Death Benefit:</b> In the unfortunate event of the policyholder's demise during the policy term, the nominee will receive the sum assured plus bonuses.
        </li>
        <li>
          <b>Loan Facility:</b> After paying premiums for a few years, you can avail of a loan against the policy, offering liquidity during emergencies.
        </li>
        <li>
          <b>Tax Benefits:</b> The premiums paid and the maturity amount received are eligible for tax deductions under Section 80C and 10(10D) of the Income Tax Act.
        </li>
      </ul>
    </div>

    <h4>Input Parameters:</h4>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li>
          <b>Sum Assured:</b> This is the guaranteed amount that the policyholder or the nominee will receive. The sum assured can vary based on the individual's financial goals.
        </li>
        <li>
          <b>Policy Term (Years):</b> This is the number of years for which the policyholder will pay the premiums. The policy term can range from 15 to 35 years.
        </li>
        <li>
          <b>Age:</b> The age of the policyholder when the policy is purchased. The premium amount is influenced by the age at entry; younger policyholders typically pay lower premiums.
        </li>
        <li>
          <b>Bonus Rate:</b> The bonus is declared annually by LIC per ₹1000 of the sum assured. It usually ranges between ₹40 and ₹50 and is a key component in determining the maturity value.
        </li>
      </ul>
    </div>

    <h4>Bonus and Maturity Calculation:</h4>
    <p style={{fontFamily:'sans-serif'}}>
      The calculator also provides a breakdown of the annual bonuses declared by LIC. These bonuses accumulate over the policy term and significantly boost the maturity value.
      The total maturity value is calculated by adding the sum assured and the cumulative bonus accrued over the years. This gives you a clear estimate of the payout you can expect at the end of the policy term.
    </p>

    <h4>Summary of Calculated Results:</h4>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li>
          <b>Monthly Premium:</b> The calculated monthly premium amount, which helps you understand the cost of maintaining the policy.
        </li>
        <li>
          <b>Total Premium Paid:</b> The total amount paid over the course of the policy, showing your financial commitment.
        </li>
        <li>
          <b>Maturity Amount:</b> The total payout you will receive at the end of the policy term, including both the sum assured and bonuses.
        </li>
        <li>
          <b>Bonus Accumulation:</b> A detailed breakdown of the annual bonuses and the total bonus accumulated over the policy term.
        </li>
        <li>
          <b>Death Benefit:</b> An overview of the benefits provided to the nominee in case of the policyholder’s death during or after the policy term.
        </li>
      </ul>
    </div>

    <h4>Frequently Asked Questions (FAQs):</h4>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li>
          <b>What happens if I stop paying premiums?</b><br/>
          If you stop paying premiums after the policy acquires a surrender value (typically after three years), the policy becomes a "paid-up" policy, and you will receive a reduced sum assured at maturity or in the event of death.
        </li>
        <li>
          <b>Can I surrender my LIC Jeevan Anand policy?</b><br/>
          Yes, after paying premiums for at least three years, you can surrender your policy. The surrender value will be lower than the total premiums paid and the bonuses declared.
        </li>
        <li>
          <b>Is there any loan facility on LIC Jeevan Anand?</b><br/>
          Yes, after paying premiums for a few years, you can avail of a loan against your policy. The loan amount will depend on the surrender value of the policy.
        </li>
        <li>
          <b>What is the premium payment frequency?</b><br/>
          You can choose to pay premiums annually, semi-annually, quarterly, or monthly, depending on your financial convenience.
        </li>
      </ul>
    </div>

    <h4>Disclaimer:</h4>
    <p style={{fontFamily:'sans-serif', fontSize:'1rem'}}>
      The LIC Jeevan Anand Calculator provides an estimate of the policy benefits based on the input values. The actual values may vary depending on LIC’s bonus declarations, policy terms, and other factors. Always consult an LIC agent or advisor for precise calculations and personalized advice before purchasing the policy.
    </p>
  </div>
</div>


            </div>
          </div>
          <div className='col-md-3'>
            <Sidebar/>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default JeevanAnandCalculator;
