import React ,{useEffect} from 'react'
import Layout from '../Componets/Layout'
import { Link } from 'react-router-dom'
import './About.css'
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout  pageTitle="Contact Us | LicCalculators"
    metaDescription="Get in touch with LICCalculators for support or inquiries regarding our LIC tools and calculators."
    metaKeywords="contact LICCalculators, support, inquiries"
    canonicalUrl="https://liccalculators.com/contact-us"
    robotsContent="index, follow"
    ogTitle="Contact Us | LICCalculators"
    ogDescription="Reach out to us for support or questions about our LIC calculators and tools."
    ogUrl="https://liccalculators.com/contact-us"
    ogImage="https://liccalculators.com/img/new_logo.png"
    schemaData={{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "url": "https://liccalculators.com/contact-us",
      "name": "Contact Us",
      "description": "Get in touch with LICCalculators for any support or inquiries.",
      "publisher": {
        "@type": "Organization",
        "name": "LICCalculators",
        "logo": {
          "@type": "ImageObject",
          "url": "https://liccalculators.com/img/new_logo.png"
        }
      }
    }}>
    <div className="image-aboutus-banner" >
   <div className="container">
 
    <div className="row">
        <div className="col-md-12">
         <h1 className="lg-text">Contact Us</h1>
		  <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/"  className="text-white font-weight-bold text-decoration-none">Home</Link></li>
        <li className="breadcrumb-item active text-decoration-none" aria-current="page">Contact Us</li>
      </ol>
    </nav>
     </div>
    </div>
</div>
</div>

<section class="bg-light py-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <h2 class="mb-4 display-5 text-center">Contact Us</h2>
          <p class="text-center mb-4" id='about-para'>Please fill out the form below to connect with us.</p>
          <p class="text-center mb-4" id='about-para'>
            Email: <Link to="mailto:ashleemedia04@gmail.com">ashleemedia04@gmail.com</Link> <br/>
            WhatsApp: <span> +91 8871740585</span><br/>
            <Link to='https://forms.gle/YERy1zwkozxN419F6' target='_blank'>Google Form</Link>
          </p>
        </div>
      </div>
    </div>
  </section>

    </Layout>
  )
}

export default ContactUs
