import React ,{useEffect} from 'react'
import Layout from '../Componets/Layout'
import { Link } from 'react-router-dom'

const Tools = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout pageTitle="All Tools -  LicCalculators"
    metaDescription="Explore all the tools provided by LICCalculators to help with your LIC calculations and financial planning."
    metaKeywords="LIC tools, financial calculators, all LIC calculators"
    canonicalUrl="https://liccalculators.com/all-tools"
    robotsContent="index, follow"
    ogTitle="All Tools"
    ogDescription="Browse through all the calculators and tools available on LICCalculators to manage your LIC investments."
    ogUrl="https://liccalculators.com/all-tools"
    ogImage="https://liccalculators.com/img/new_logo.png"
    schemaData={{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "url": "https://liccalculators.com/all-tools",
      "name": "All Tools - LicCalculators",
      "description": "Browse through all the calculators and tools available on LICCalculators.",
      "publisher": {
        "@type": "Organization",
        "name": "LICCalculators",
        "logo": {
          "@type": "ImageObject",
          "url": "https://liccalculators.com/img/new_logo.png"
        }
      }
    }} >
      
      {/* tools section  */}

      <div className="image-aboutus-banner" >
   <div className="container">
 
    <div className="row">
        <div className="col-md-12">
         <h1 className="lg-text">All tools </h1>
		 <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/"  className="text-white font-weight-bold text-decoration-none">Home</Link></li>
        <li className="breadcrumb-item active text-decoration-none" aria-current="page">All tools</li>
      </ol>
    </nav>
       </div>
    </div>
</div>
</div>

 <div className='container-fluid' style={{marginTop:'40px'}}>
<p className='text-center' style={{fontSize:'30px',marginBottom:'30px'}}>Explore Our Lic Calculators</p>
 <div className="container">
 
  <div className="row">
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  {/* <img className="card-img-top" src="/img/SIP (1).webp" height={'200vh'} alt="SIP Calculator img"/> */}
  <div className="card-body">
    <h5 className="card-title"><b>LIC Personal Loan Calculator</b></h5>
    <p className="card-text">Estimates monthly payments and total interest for personal loans from LIC. </p>
    <Link to='/lic-personal-loan-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  {/* <img class="card-img-top" src="/img/FD.webp"  height={'200vh'} alt="FD Calculator img" /> */}
  <div class="card-body">
    <h5 class="card-title"><b>LIC Home Loan Calculator</b></h5>
    <p class="card-text">Designed to calculate EMI and total interest for home loans from LIC, providing clear insights for better financial planning. </p>
    <Link to='/lic-home-loan-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>

  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  {/* <img class="card-img-top" src="/img/SWP.webp"  height={'200vh'} alt="SWP Calculator img"/> */}
  <div class="card-body">
    <h5 class="card-title"><b>LIC Loan Calculator</b></h5>
    <p class="card-text"> A versatile tool to compute EMIs and interest for various types of loans from LIC, aiding in effective loan management.</p>
    <Link to='/lic-loan-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>

  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  {/* <img class="card-img-top" src="/img/SI.webp"  height={'200vh'} alt="Simple Interest Calculator img" /> */}
  <div class="card-body">
    <h5 class="card-title"><b>LIC Jeevan Anand Calculator</b></h5>
    <p class="card-text">Calculates premiums for LIC's Jeevan Anand policy and visualizes yearly premiums.</p>
    <Link to='/lic-jeevan-anand-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>

  </div>
</div>
    </div>
  </div>

  {/* second rows  */}

  <div className="row mt-5">
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  {/* <img className="card-img-top" src="/img/MRF.webp" height={'200vh'}   alt="Mutual Fund Return Calculator img" /> */}
  <div className="card-body">
    <h5 className="card-title"><b>Jeevan Labh Calculator
</b></h5>
    <p className="card-text">The Jeevan Labh Calculator helps calculate premiums, maturity amounts, and benefits for LIC's Jeevan Labh plan.</p>
    <Link to='/lic-jeevan-labh-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>

  <div className="card-body">
    <h5 className="card-title"><b>Jeevan Saral Maturity Calculator
</b></h5>
    <p className="card-text"> Jeevan Saral Maturity Calculator calculates maturity benefits from premiums, policy term, and age.</p>
    <Link to='/lic-jeevan-saral-maturity-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  {/* <img className="card-img-top" src="/img/SSY.webp" height={'200vh'}  alt="Sukanya Samriddhi Yojana Calculator img" /> */}
  <div className="card-body">
    <h5 className="card-title"><b>Jeevan Umang Calculator</b></h5>
    <p className="card-text">The Jeevan Umang Calculator estimates premiums, survival benefits, and maturity for LIC’s Jeevan Umang policy.</p>
    <Link to='/lic-jeevan-umang-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>

  <div className="card-body">
    <h5 className="card-title"><b>Lic Maturity Calculator</b></h5>
    <p className="card-text">The LIC Maturity Calculator computes maturity values, bonuses, and final payouts for various LIC policies.</p>
    <Link to='/lic-maturity-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
 </div>
  </div>

    {/* third rows  */}

    <div className="row mt-5">
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>Lic Premium Calculator
</b></h5>
    <p className="card-text">Calculates LIC premium based on sum assured, age, policy term, and frequency dynamically.</p>
    <Link to='/lic-premium-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>

  <div className="card-body">
    <h5 className="card-title"><b>Surrender Value Calculator
</b></h5>
    <p className="card-text">Calculates policy surrender value based on premiums paid and policy duration dynamically.</p>
    <Link to='/lic-surrender-value-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  {/* <img className="card-img-top" src="/img/SSY.webp" height={'200vh'}  alt="Sukanya Samriddhi Yojana Calculator img" /> */}
  <div className="card-body">
    <h5 className="card-title"><b>Lic Sip Calculator</b></h5>
    <p className="card-text">Calculates LIC SIP returns based on investment amount, tenure, rate, displaying results graphically.</p>
    <Link to='/lic-sip-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>

  <div className="card-body">
    <h5 className="card-title"><b>Lic Pension Plan Calculator</b></h5>
    <p className="card-text">Calculates LIC pension plan returns based on premiums, tenure, and expected annuity rate.</p>
    <Link to='/lic-pension-plan-calculator'><button className='btn btn-success' style={{marginLeft:'4vw'}}>Try Now</button></Link>
  </div>
</div>
 </div>
  </div>
</div>
</div>
 

    </Layout>
  )
}

export default Tools
