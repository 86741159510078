import React, { useState , useEffect} from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import Layout from '../Componets/Layout';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Sidebar from '../Pages/Sidebar';
import AdHeader from '../Componets/AdHeader';


const HomeLoanCalculator = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [principal, setPrincipal] = useState('');
  const [interestRate, setInterestRate] = useState('8.50'); // Default interest rate
  const [loanTenure, setLoanTenure] = useState('');
  const [emiData, setEmiData] = useState(null);


  const handleSubmit = (e) => {
    e.preventDefault();

    const principalAmount = parseFloat(principal);
    const rateOfInterest = parseFloat(interestRate) / 12 / 100;
    const tenureInMonths = parseInt(loanTenure) * 12;

    const emi = (principalAmount * rateOfInterest * Math.pow(1 + rateOfInterest, tenureInMonths)) / 
                (Math.pow(1 + rateOfInterest, tenureInMonths) - 1);

    const totalPayment = emi * tenureInMonths;
    const totalInterest = totalPayment - principalAmount;

    setEmiData({
      emi: emi.toFixed(2),
      totalPrincipal: principalAmount.toFixed(2),
      totalInterest: totalInterest.toFixed(2),
      totalAmount: totalPayment.toFixed(2),
    });
  };

  const chartData = emiData
    ? {
        labels: ['Total Principal', 'Total Interest'],
        datasets: [
          {
            label: 'EMI Breakdown',
            data: [emiData.totalPrincipal, emiData.totalInterest],
            backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(255, 99, 132, 0.6)'],
            borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
            borderWidth: 1,
          },
        ],
      }
    : null;

    const exportToExcel = () => {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([
        { 'Monthly Emi': emiData.emi },
        { 'Principal Amount': emiData.totalPrincipal },
        { 'Total Amount	': emiData.totalAmount },
        { 'Total Interest': emiData.totalInterest }
      ]);
  
      XLSX.utils.book_append_sheet(wb, ws, 'Post Office RD Calculator Data');
      XLSX.writeFile(wb, 'Post_Office_RD_Calculator.xlsx');
    };

    const formatCurrency = (amount) => {
      const formatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      return formatter.format(amount);
    };
  return (
<Layout  pageTitle="LIC Home Loan Calculator | LICCalculators"
      metaDescription="Calculate your home loan EMI, principal, and interest using the LIC Home Loan Calculator."
      metaKeywords="LIC home loan calculator, home loan EMI, LIC loan, interest rates"
      canonicalUrl="https://liccalculators.com/lic-home-loan-calculator"
      robotsContent="index, follow"
      ogTitle="LIC Home Loan Calculator | LICCalculators"
      ogDescription="Calculate your home loan EMI, principal, and interest with our LIC Home Loan Calculator."
      ogUrl="https://liccalculators.com/lic-home-loan-calculator"
      ogImage="https://liccalculators.com/assets/img/new_logo.png"
      schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://liccalculators.com/lic-home-loan-calculator",
        "name": "LIC Home Loan Calculator",
        "description": "Calculate your home loan EMI, principal, and interest using the LIC Home Loan Calculator.",
        "publisher": {
          "@type": "Organization",
          "name": "LICCalculators",
          "logo": {
            "@type": "ImageObject",
            "url": "https://liccalculators.com/img/new_logo.png"
          }
        }
      }}>
<div className='container-fluid'>
  <div className='row'>
    <div className='col-md-1'>

    </div>
    <div className='col-md-8 main-section'>
     <div className='container'>
     <div className='header-ads'>
      <AdHeader/>
     </div>
     <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><Link to="/" style={{textDecoration:'none'}}>Home</Link></li>
        <li class="breadcrumb-item"><Link to="/all-tools" style={{textDecoration:'none'}}>All Tools</Link></li>
        <li class="breadcrumb-item active" aria-current="page">Lic Home loan Calculator</li>
      </ol>
        </nav>
        <form onSubmit={handleSubmit} >
        <h1 className='text-center display-4'>Lic Home Loan Calculator</h1>
        <p className='text-center'>Your Guide to Smarter LIC Home Loan Planning</p>
        <div className='container text-center'>
          <div className='row'>
            <div className='col-md-12'>
            <label htmlFor='Principal' className='form-label label'><b>Principal Amount (INR):</b> <span className="text-danger">*</span></label>
            <input
            type="number"
            value={principal}
            onChange={(e) => setPrincipal(e.target.value)}
            name='Principal Amount'
            className='form-control'
            id="investmentAmount"
            placeholder='Enter Principal Amount'
            required
          />
            </div>
          </div>
          <div className='row input-row'>
            <div className='col-md-12'>
            <label htmlFor='interest' className='form-label label'><b>Interest Rate (%): </b><span className="text-danger">*</span></label>
            <input
            type="number"
            step="0.01"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            className='form-control'
            id="investmentPeriod" 
            name = "Interest Rate"
            required
          />
            </div>
          </div>
          <div className='row input-row'>
            <div className='col-md-12'>
            <label htmlFor='LoanTenure' className='form-label label'><b>Loan Tenure (Years):</b><span className="text-danger">*</span> </label>
            <input
            type="number"
            className='form-control'
            name='Loan Tenure'
            value={loanTenure}
            onChange={(e) => setLoanTenure(e.target.value)}
            id="annualInterestRate"
            placeholder='Enter Loan Tenure'
            required
          />
            </div>
          </div>
          <div className='row input-row'>
            <div className='col-md-12'>
            
   
      <button type='submit'  className='btn btn-success'>Calculate EMI</button>

            </div>
          </div>
        </div>
        </form>

        {emiData && (
          <div className='container'>
            <hr></hr>
            <div className='row'>
            <h5>Summary :</h5> <br></br>
            <div className='row'>
              <div className='table-responsive'>
                <table className='table'>
                  <tbody>
                    <tr>
                      <th scope='col'>
                      Monthly EMI:
                      </th>
                      <td>{formatCurrency(emiData.emi)}</td>
                    </tr>
                    <tr>
                      <th scope='col'>Total Principal:</th>
                      <td>{formatCurrency(emiData.totalPrincipal)}</td>
                    </tr>
                    <tr>
                      <th scope='col'>Total Interest:</th>
                      <td>{formatCurrency(emiData.totalInterest)}</td>
                    </tr>
                    <tr>
                      <th scope='col'>Total Amount:</th>
                      <td>{formatCurrency(emiData.totalAmount)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button className="btn btn-success" id='annualInterestRate' onClick={exportToExcel} >Export to Excel</button>
            </div>
        
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
            <h2>EMI Breakdown</h2>
            <Bar data={chartData} />
            </div>
          </div>
        </div>
          
            </div>
          </div>
        )}

        <div className='container'>
  <div className='row' style={{marginTop:"50px"}}>
    <hr></hr>
    <h3 className='text-center text-black'>About LIC Home Loan Calculator</h3>
    <hr></hr>
    <p style={{fontFamily:"sans-serif"}}>
      The LIC Home Loan Calculator is a powerful financial tool designed to provide users with accurate estimates of their monthly EMI 
      (Equated Monthly Installment) when applying for a home loan from LIC Housing Finance. By simply entering your loan amount, interest rate, 
      and tenure, you can easily calculate your monthly payments and better understand your financial obligations.
    </p>

    <h4 style={{fontSize:'2rem'}}>Key Inputs :</h4>
    <div style={{fontSize:"1.1rem"}}>
      <ul>
        <li>
          <b>Principal Amount (Loan Amount):</b> This is the total amount of money you are borrowing for the home loan from LIC Housing Finance. It can vary based on your property value and eligibility.
        </li>
        <li>
          <b>Interest Rate (%):</b> The annual percentage rate charged by LIC on the loan. Fixed or floating rates may apply based on the type of loan.
        </li>
        <li>
          <b>Loan Tenure (Years):</b> The time duration, usually in years, over which you plan to repay the loan. Typical tenures range from 5 to 30 years.
        </li>
      </ul>
    </div>

    <h4 style={{fontSize:'2rem'}}>Key Outputs :</h4>
    <div style={{fontSize:"1.1rem"}}>
      <ul>
        <li>
          <b>Monthly EMI:</b> The amount you will need to pay monthly towards repaying the loan, including both the interest and principal amount.
        </li>
        <li>
          <b>Total Interest:</b> The total interest you will have paid over the entire loan period, calculated based on your input values.
        </li>
        <li>
          <b>Total Principal:</b> The original loan amount borrowed from LIC.
        </li>
        <li>
          <b>Total Amount:</b> The total repayment amount, which includes the total interest and principal over the course of the loan.
        </li>
      </ul>
    </div>

    <h4 style={{fontSize:'2rem'}}>Why Use the LIC Home Loan Calculator?</h4>
    <div style={{fontSize:"1.1rem"}}>
      <ul>
        <li>
          <b>Budget Management:</b> Helps you estimate your monthly financial commitment and make well-informed decisions when planning to buy a house.
        </li>
        <li>
          <b>Easy Comparison:</b> Test different combinations of loan amounts, interest rates, and tenures to choose a loan structure that best fits your budget.
        </li>
        <li>
          <b>Accurate Projections:</b> Get precise calculations, minimizing errors and providing transparency in the loan planning process.
        </li>
        <li>
          <b>Prepayment Insights:</b> Use the calculator to estimate how partial prepayments or full prepayment would impact your overall loan tenure and interest outflow.
        </li>
        <li>
          <b>Real-Time Updates:</b> Adjust your values and see immediate changes to your loan details, helping you analyze different scenarios on the go.
        </li>
      </ul>
    </div>

    <h4 style={{fontSize:'2rem'}}>Additional Features of LIC Home Loans</h4>
    <div style={{fontSize:"1.1rem"}}>
      <ul>
        <li>
          <b>Flexible Loan Tenure:</b> LIC offers loan tenures ranging from 5 to 30 years, depending on your eligibility and repayment capacity.
        </li>
        <li>
          <b>Competitive Interest Rates:</b> LIC offers both fixed and floating interest rate options. You can select the type that suits your financial planning.
        </li>
        <li>
          <b>Low Processing Fees:</b> LIC Housing Finance provides competitive processing fees for their home loans, making it an attractive option for home buyers.
        </li>
        <li>
          <b>Balance Transfer Facility:</b> LIC Housing Finance allows you to transfer your existing home loan from another bank to LIC at competitive interest rates, helping you save on EMI.
        </li>
        <li>
          <b>Top-Up Loan:</b> If you need additional funds, LIC offers top-up loans on your existing home loan at a lower interest rate, which can be used for home renovation or other needs.
        </li>
      </ul>
    </div>

    <h4 style={{fontSize:'2rem'}}>Frequently Asked Questions (FAQs)</h4>
    <div style={{fontSize:"1.1rem"}}>
      <ul>
        <li>
          <b>How is the EMI calculated?</b><br/>
          The EMI is calculated using the formula:<span>EMI = [P × r × (1 + r)<sup>n</sup>] / [(1 + r)<sup>n</sup> - 1]</span>
          , where P is the principal loan amount, R is the monthly interest rate, and N is the loan tenure in months.
        </li>
        <li>
          <b>Can I change my EMI amount during the loan tenure?</b><br/>
          Yes, by making prepayments or opting for a balance transfer, you can reduce your EMI burden.
        </li>
        <li>
          <b>What is a fixed vs. floating interest rate?</b><br/>
          A fixed interest rate remains the same throughout the loan tenure, while a floating rate may fluctuate based on market conditions.
        </li>
        <li>
          <b>Can I apply for a top-up loan with LIC?</b><br/>
          Yes, you can apply for a top-up loan on your existing home loan at competitive interest rates.
        </li>
        <li>
          <b>What documents are required for a LIC home loan?</b><br/>
          You will need to submit identity proof, income proof, property-related documents, and bank statements, among other things, to complete the loan application.
        </li>
      </ul>
    </div>

    <h4 style={{fontSize:'2rem'}}>How to Use the LIC Home Loan Calculator:</h4>
    <div style={{fontSize:"1.1rem"}}>
      <ul>
        <li>Step 1: Enter the desired loan amount you wish to borrow.</li>
        <li>Step 2: Input the applicable interest rate (in percentage).</li>
        <li>Step 3: Choose the loan tenure (in years).</li>
        <li>Step 4: Click the 'Calculate' button to view your monthly EMI, total interest payable, and total repayment amount.</li>
        <li>Step 5: Modify the values to explore different loan scenarios.</li>
      </ul>
      <p>
        This calculator is user-friendly and provides instant results, helping you save time and plan your home loan with confidence.
      </p>
    </div>

    <h4 style={{fontSize:'2rem'}}>Tips for Managing Your Home Loan Effectively</h4>
    <div style={{fontSize:"1.1rem"}}>
      <ul>
        <li><b>Choose a Tenure Wisely:</b> Opting for a shorter loan tenure reduces the overall interest outflow but increases EMI payments. Balance your EMI affordability with your savings goals.</li>
        <li><b>Make Prepayments:</b> Whenever possible, make partial prepayments to reduce your outstanding principal, which helps in lowering future EMIs and total interest.</li>
        <li><b>Monitor Interest Rates:</b> Keep an eye on market interest rate trends, especially if you have a floating rate. If rates drop significantly, consider a balance transfer to lower your EMI.</li>
        <li><b>Plan for Rate Hikes:</b> If you opt for a floating interest rate, be prepared for potential rate hikes in the future by creating a buffer in your budget.</li>
        <li><b>Loan Insurance:</b> Consider taking home loan insurance to secure your loan repayments in case of unforeseen circumstances such as job loss or disability.</li>
      </ul>
    </div>

    <div style={{marginTop: "30px"}}>
      <h4 className='text-center'>Disclaimer:</h4>
      <p style={{fontFamily: "sans-serif", fontSize: "1rem"}}>
        The LIC Home Loan Calculator provides an estimated EMI and loan details. The actual EMI, interest rate, and other amounts may vary based on the final terms of the loan agreement. Please consult with LIC Housing Finance for precise details before making any financial commitments.
      </p>
    </div>
  </div>
</div>


      
     </div>
    </div>
    <div className='col-md-3'>
      <Sidebar></Sidebar>
    </div>
  </div>
</div>



</Layout>
  );
};

export default HomeLoanCalculator;
