import React from 'react'
import { Link } from 'react-router-dom'
import './Header.css'

const Footer = () => {
  return (
    <>
     <section className="pt-24 pb-8  position-relative overflow-hidden mt-4" style={{backgroundColor:'#26B75D'}}>
  <div className="container position-relative" style={{marginTop:"30px"}}>
    <div className="row pb-20 mb-6 border-bottom border-secondary-dark">
      <div className="col-12 col-lg-5 mb-16 mb-lg-0">
        <Link className="d-inline-block mb-8" to="#">
          <img src="/img/new_logo.png" alt="Footer-logo" />
        </Link><br></br>
        <p className="text-secondary mw-xs mb-24 text-white" id='about-para'>Welcome to our comprehensive suite of LIC calculators designed to empower you with the tools you need to make informed decisions about your investments and savings.</p>
     
      </div>
      <div className="col-12 col-lg-7">
        <div className="row">
          <div className="col-6 col-md-4 mb-16 mb-md-0">
            <h6 className="fs-9 mb-6 text-uppercase text-secondary">Important Link</h6>
            <ul className="list-unstyled">
            <li className="mb-4"><Link className="btn btn-link  Link p-0 text-white text-decoration-none footer-link" to="/about-us">About Us</Link></li>
             
              <li className='mb-4'><Link className="btn btn-link p-0 text-white Link text-decoration-none footer-link" to="/contact-us">Report bug</Link></li>
              <li><Link className="btn btn-link p-0 text-white Link text-decoration-none footer-link" to="">Donate Us</Link></li>
              
            
            </ul>
          </div>
         
          <div className="col-6 col-md-4">
            <h6 className="fs-9 mb-6 text-uppercase text-secondary">Pages</h6>
            <ul className="list-unstyled">
            <li className="mb-4"><Link className="btn btn-link  Link p-0 text-white text-decoration-none footer-link" to="/privacy-and-policy">Privacy Policy</Link></li>
              <li className="mb-4"><Link className="btn btn-link Link p-0 text-white text-decoration-none footer-link" to="/disclaimer">Disclaimer</Link></li>
              <li className="mb-4"><Link className="btn btn-link Link p-0 text-white text-decoration-none footer-link" to="/terms-and-conditions">Term & Conditions</Link></li>
             
            
            </ul>
          </div>
          <div className="col-6 col-md-4">
          <h6 className="fs-9 mb-6 text-uppercase text-secondary">Address</h6>
          <ul className="list-unstyled">
          <span style={{color:"white"}}>Gram Katra Post Saleha Panna MP 48833</span><br></br>
          <br></br>
          <span style={{color:"white"}}>
            Contact - +91 8871740585
          </span>

          </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="text-center">
      <p className="fs-9 text-secondary-light mb-4  mt-3 text-white">Copyright © 2024 <span style={{color:'#0D6EFD'}}>licCalculators.com</span> All Rights Reserved</p>
    </div>
  </div>
</section>
    </>
  )
}

export default Footer
