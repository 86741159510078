import React from 'react'
import { Link } from 'react-router-dom'

import AdSidebar from '../Componets/AdSidebar'

const Sidebar = () => {
  return (
    <div>
      <div className='ads'>
      <AdSidebar/>
      </div>

<div className='text'>
<h3 className='text-center' style={{color:'white', paddingTop:'0.5rem'}}>Important Tools</h3>
</div>
<div className='tools-list'>
 <ul>
  <li> <Link to='/lic-loan-calculator' target="_blank">Lic Loan Calculator</Link></li>
  <li><Link to='/lic-maturity-calculator' target="_blank">Lic Maturity Calculator</Link></li>
  <li> <Link to='/lic-home-loan-calculator' target="_blank">Lic Home Loan Calculator</Link></li>
  <li><Link to='/lic-premium-calculator' target="_blank"> Lic Premium Calculator</Link></li>
  <li><Link to='/lic-pension-plan-calculator' target="_blank">Pension Plan Calculator</Link></li>
  <li><Link to="/lic-jeevan-labh-calculator" target="_blank">Jeevan Labh  Calculator</Link></li>
  <li><Link to="/lic-surrender-value-calculator" target="_blank">Jeevan Surrender Value Calculator</Link></li>
  <li><Link to="/lic-jeevan-saral-maturity-calculator" target="_blank">Jeevan Saral Maturiy Calculator</Link></li>
   
  
   
   
 </ul>
</div>
    </div>
  )
}

export default Sidebar
