import React ,{useEffect} from 'react'
import Layout from '../Componets/Layout'
import { Link } from 'react-router-dom'

const Donate = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  return (

    <Layout  pageTitle="Donate Us - LicCalculators"
    metaDescription="Support LICCalculators by making a donation. Your contributions help us maintain and improve our LIC calculators."
    metaKeywords="donate LICCalculators, support, financial tools"
    canonicalUrl="https://liccalculators.com/donate-us"
    robotsContent="index, follow"
    ogTitle="Donate Us - LicCalculators"
    ogDescription="Help us continue to provide valuable LIC calculators and financial tools by making a donation."
    ogUrl="https://liccalculators.com/donate-us"
    ogImage="https://liccalculators.com/img/new_logo.png"
    schemaData={{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "url": "https://liccalculators.com/donate-us",
      "name": "Donate Us",
      "description": "Support LICCalculators with your donation.",
      "publisher": {
        "@type": "Organization",
        "name": "LICCalculators",
        "logo": {
          "@type": "ImageObject",
          "url": "https://liccalculators.com/img/new_logo.png"
        }
      }
    }}>
     <div className="image-aboutus-banner" >
   <div className="container">
 
    <div className="row">
        <div className="col-md-12">
         <h1 className="lg-text">Donate Us</h1>
		 <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/"  className="text-white font-weight-bold text-decoration-none">Home</Link></li>
        <li className="breadcrumb-item active text-decoration-none" aria-current="page">Donate Us</li>
      </ol>
    </nav>
       </div>
    </div>
</div>
</div>
      <section class="bg-light py-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8 text-center">
          <h2 className="mb-4 display-5"><span class="emoji">&#x1F447;</span> Donate Us <span class="emoji">&#x1F447;</span></h2>
          <p className="lead mb-4" id='about-para'>Your support helps us continue our important work. Here's why people donate:</p>
          <ul className="list-unstyled" id='about=para'>
            <li id='about-para'><strong >Make a difference:</strong> Your donation directly impacts the lives of those we serve, making a meaningful difference in our community.</li><br></br>
            <li id='about-para'><strong>Support our mission:</strong> By donating, you're helping us achieve our goal of providing finance calculator tools free of cost to everyone, making financial planning accessible to all.</li><br></br>
            <li id='about-para'><strong >Ensure sustainability:</strong> Your contribution ensures the sustainability of our programs and initiatives, allowing us to continue serving those in need over the long term.</li><br></br>
            <li id='about-para'><strong >Join a cause:</strong> Join a community of supporters who believe in our cause and are committed to creating positive change together.</li>

          </ul>

          <p class="lead mb-4" id='about-para'>Your donations also help cover essential expenses such as server costs for maintaining our online presence and team salary to support our dedicated staff members.</p>
          <img src="/img/payments.jpeg"  width={'300px'} alt="Payment QR Code" className="img-fluid mb-4" />
          <p className="lead mb-4"><span className='heart'>&#x2764;</span>We greatly appreciate your generosity and support!<span className='heart'>&#x2764;</span></p>
        </div>
      </div>
    </div>
  </section>
 

 
    </Layout>
  )
}

export default Donate
