import React , {useEffect} from 'react'
import Layout from '../Componets/Layout'
import './About.css'
import { Link } from 'react-router-dom'

const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
  return (
    <Layout   pageTitle="About Us - LicCalculators"
    metaDescription="Learn more about LICCalculators and how we help you with LIC-related financial tools."
    metaKeywords="about LICCalculators, LIC tools, financial services"
    canonicalUrl="https://liccalculators.com/about-us"
    robotsContent="index, follow"
    ogTitle="About Us - LicCalculator"
    ogDescription="Discover who we are and how we provide valuable LIC calculators and financial tools."
    ogUrl="https://liccalculators.com/about-us"
    ogImage="https://liccalculators.com/img/new_logo.png"
    schemaData={{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "url": "https://liccalculators.com/about-us",
      "name": "About Us",
      "description": "Learn more about LICCalculators and our mission.",
      "publisher": {
        "@type": "Organization",
        "name": "LICCalculators",
        "logo": {
          "@type": "ImageObject",
          "url": "https://liccalculators.com/img/new_logo.png"
        }
      }
    }} >

     

<div className="image-aboutus-banner" >
   <div className="container">
 
    <div className="row">
        <div className="col-md-12">
         <h1 className="lg-text">About Us</h1>
		 <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/"  className="text-white font-weight-bold text-decoration-none">Home</Link></li>
        <li className="breadcrumb-item active text-decoration-none" aria-current="page">About us</li>
      </ol>
    </nav>
       </div>
    </div>
</div>
</div>

<div className="aboutus-secktion paddingTB60">
    <div className="container">
        <div className="row">
                	<div className="col-md-6">
                    	<img src="./img/about.jpg" id='about-img' height={'400px'} alt="About-images" />
                    </div>
                    <div className="col-md-6">
                    <h2 id='about-message'>Welcome to LicCalculator</h2>
                    	<p id='about-para'>your one-stop destination for all your financial planning needs. At LicCalculator, we are committed to providing you with powerful and user-friendly financial tools to help you make informed decisions and achieve your financial goals.</p>
                       <h3>Our Mission</h3>
                       <p id='about-para'>Our mission is to empower individuals to take control of their finances by providing them with easy-to-use tools and resources that simplify complex financial calculations and concepts.</p>
                       <h3>Why Choose Us</h3>
                       <p id='about-para'> Our calculators are designed to deliver accurate results, ensuring you have reliable information for your financial planning.</p>
                       <p id='about-para'>From basic interest calculations to complex investment planning, we offer a wide range of calculators to address various financial needs.</p>
                    </div>
</div>
    </div>
</div>
<hr/>


<div className="container">
    <div className="inline-content">
      <div>
        <h4>Get in touch with us!</h4>
        <p>Have questions or need assistance? We're here to help.</p>
      </div>
          <button className="contact-btn">
      <Link className='text-dec' to='/contact-us' style={{ textDecoration: 'none', color: 'white' }}>Contact Us</Link>
    </button>
    </div>
  </div>
  
<div className="container">
    <h2 className="text-center mb-5 display-3">Our Team</h2>
    <div className="row">
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="card h-100 text-center">
          <img src="/img/author.jpeg" height={'300px'} className="card-img-top" alt="Team Member 1" />
          <div className="card-body">
            <h5 className="card-title">Ramjee Chaurasiya</h5>
            <p className="card-text" id='about-para'>founder & Developer</p>
            <p id='about-para'>Ramjee, the visionary behind LicCalculator, combines his passion for finance with cutting-edge technology to create innovative solutions for everyday financial challenges. With a background in finance and computer science,</p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="card h-100 text-center">
          <img src="/img/himanshu.jpeg" height={'300px'} className="card-img-top" alt="Team Member 2" />
          <div className="card-body">
            <h5 className="card-title">Himanshu Chaurasiya</h5>
            <p id='about-para'> Data Scientist</p>
            <p className="card-text" id='about-para'>Experience the difference of working with seasoned finance professionals at LicCalculator. Our experts are here to guide you every step of the way on your financial journey.</p>
          
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="card h-100 text-center">
          <img src="/img/shivam.jpeg" height={'300px'} className="card-img-top" alt="Team Member 3" />
          <div className="card-body">
            <h5 className="card-title">Shivam Chaurasiya</h5>
            <p id='about-para'>Python Developer</p>
            <p className="card-text" id='about-para'>With LicCalculator, you can say goodbye to manual calculations and tedious coding tasks. Our collection of calculators and utilities automates repetitive processes, saving you time and effort so you can focus on what you do best - writing clean, efficient Python code.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

{/* 
  <div className="container">
  <h2 className='text-center'>Our Channel</h2>
    <div className="inline-content">
      <div>
        <h4>Join Our Whatsapp Channel to get Latest Update</h4>
      </div>
          <button className="btn btn-success">
      <Link className='btn btn-success' to='https://whatsapp.com/channel/0029VaMOzI7HQbS15Bmgfe3b' style={{ textDecoration: 'none', color: 'white' }}>Join Now</Link>
    </button>
    </div>
      <div className="inline-content" style={{marginTop:'20px'}}>
      <div>
        <h4>Join Our Telegram Channel to get Latest Update</h4>
      </div>
          <button className="btn btn-success">
      <Link className='btn btn-success' to='https://t.me/moviePoint5654' style={{ textDecoration: 'none', color: 'white' }}>Join Now</Link>
    </button>
    </div>
  </div> */}

   

    </Layout>
  )
}

export default About;
